  // Essential for all components
import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";

import BaseListPage from "../../components/101BaseListPage/BaseListPage";
import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import {logout} from '../../Redux/Action/authAction';
import {connect} from "react-redux";
import * as apiIndustry from "../../Api/_ApiIndustry";
import moment from "moment";
import {isAdmin} from "../../utils/Utility";
import {MySnackbarContentWrapper} from "../../components/102MaterialDesign/MySnackbarContentWrapper";
import Snackbar from "@material-ui/core/Snackbar";
import {getValAfterSlash,handleSpecialFieldName} from "../../utils/PaginationUtils";
import queryString from 'query-string';


const ID_ATTRIBUTE = apiIndustry.ID_ATTRIBUTE;

// Define column names
const rows = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Name', link: `/industry-management/:${ID_ATTRIBUTE}`, customUrlParam: [ID_ATTRIBUTE] },
    { id: 'sequence', numeric: true, disablePadding: false, label: 'Sequence' },
    { id: 'lastmoddate', numeric: false, disablePadding: false, label: 'Update Date', type: 'date' }
];

const rowsPerPageDefault = 10;
const orderByDefault = 'sequence';
const orderDefault = 'asc';

class IndustryManagement extends Component {

    state = {
        data: null,
        openSnackbar: false,
        messageSnackbar: '',
        variantSnackbar: '',
        //pagination
        amountOfData: '',
        currentPage: 0,
        rowsPerPage: rowsPerPageDefault,
        orderBy: orderByDefault,
        order: orderDefault
    };


    componentDidMount() {
        let previousPage = null;
        const values = queryString.parse(this.props.location.search)
        if(values.previous) {
            previousPage = [...this.props.currentPaginator].find(item => item.page_id === 'industry-management');
        }
        const currentPage = previousPage ? previousPage.page : 0,
            rowsPerPage =  previousPage? previousPage.rowsPerPage : rowsPerPageDefault,
            orderBy = previousPage? previousPage.orderBy : orderByDefault,
            order = previousPage? previousPage.order : orderDefault;
        this.setState({
            currentPage: currentPage,
            rowsPerPage: rowsPerPage,
            orderBy: orderBy,
            order: order
        }, () => {
            this._getDataDynamic(currentPage*rowsPerPage, rowsPerPage, orderBy, order, true);
        })
    }

    _getDataDynamic = (skipAmount, rowsPerPage, orderBy ,order, isResetNavAndDataAmount, searchSubject) => {
        const { t, i18n } = this.props;
        orderBy = handleSpecialFieldName(orderBy);

        const params = 
            {'$orderby': `${orderBy} ${order}`, 
             '$top': rowsPerPage, 
             '$skip': skipAmount
            };
            apiIndustry.getList(params).then( (rs) => {
            if(rs && rs.status === 200) {
                if(isResetNavAndDataAmount){
                    const dataSum = getValAfterSlash(rs.headers['content-range']);
                    this.setState({amountOfData: dataSum});
                    this.props.setBreadcrumbP(
                        {
                            breadcrumbData: [
                                {
                                    title: t('IndustryManagement:breadcrumb') + ' (' + dataSum + ')',
                                    link: '/' + i18n.language + '/industry-management'
                                }
                            ]
                        }
                    );
                }
                this.setState({
                    data: this.prepareData(rs.data),
                });
            } else {
                console.log("eCb : ", rs);
            }
        })
    }

    _createButtonAction = () => {
        const { i18n } = this.props;
        this.props.history.push('/' + i18n.language + '/industry');
    }

    _updateButtonAction = (id) => {
        const { i18n } = this.props;
        this.props.history.push('/' + i18n.language + '/industry/' + id);
    }

    _deleteButtonAction = (ids, rowsPerPage) => {
        apiIndustry.deleteMany(ids).then(rs => {
            if (rs.status === 204) {
                this.setState({
                    openSnackbar: true,
                    messageSnackbar: 'Delete industry(s) successfully',
                    variantSnackbar: 'success'
                })
                this._getDataDynamic(0, rowsPerPage, orderByDefault, orderDefault, true);
            } else {
                console.log("eCb : ", rs);
            }
        });
    }

    handleLogout = () => {
        this.props.logoutP();
    }

    handleCloseSnackbar = () => {
        this.setState({ openSnackbar: false });
    };

    prepareData = data => {
        return (data && data.length > 0) ? (
            data.map(item => ({
                ...item,
                id: item[ID_ATTRIBUTE],
                update_date: moment(item.lastmoddate).format('YYYY-MM-DD'),
            }))
        ) : [];
    };

    render() {
        const { auth } = this.props;
        const { data, openSnackbar, variantSnackbar, messageSnackbar, amountOfData, currentPage, rowsPerPage, orderBy, order } = this.state;
        const PAGE_ID = 'industry-management';
        const MAIN_CLASS = 'industries-page';
        return (
            data &&
                <div>
                    <BaseListPage
                        pageId={PAGE_ID}
                        mainClass={MAIN_CLASS}
                        primaryKey={ID_ATTRIBUTE}
                        displaySearchBar={false}
                        displayActionBar={isAdmin(auth.userInfo ? auth.userInfo.role : null)}

                        createButton={true}
                        createButtonText={'Add'}
                        createButtonAction={this._createButtonAction}

                        updateButton={true}
                        updateButtonText="Edit"
                        updateButtonAction={this._updateButtonAction}

                        deleteButton={true}
                        deleteButtonText="Delete"
                        deleteButtonAction={this._deleteButtonAction}

                        getDataDynamic = {this._getDataDynamic}
                        currentPage={currentPage}
                        rows={rows}
                        data={data}
                        displaySelectBox={true}
                        orderDefault={order}
                        orderByDefault={orderBy}
                        rowsPerPageDefault={rowsPerPage}
                        rowsPerPageOptions={[5, 10, 20]}
                        amountOfData={amountOfData}
                    />
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={openSnackbar}
                        autoHideDuration={2000}
                        onClose={this.handleCloseSnackbar}
                    >
                        <MySnackbarContentWrapper
                            onClose={this.handleCloseSnackbar}
                            variant={variantSnackbar}
                            message={messageSnackbar}
                        />
                    </Snackbar>
                </div>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    currentPaginator: state.paginator.currentPaginator
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    logoutP: data => dispatch(logout(data))
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(IndustryManagement)));