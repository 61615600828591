import {api} from './_ApiFactoryWithHeader';

const ENDPOINT = 'industry_type_attributes';
export const ID_ATTRIBUTE = 'industry_type_attribute_id';

export function getList(params, cancelToken) {
    return api.get(ENDPOINT, params, null, cancelToken);
}

export function getDetail(id, params) {
    return api.get(`${ENDPOINT}/${id}`, params, null, null);
}

export function create(body) {
    return api.post(ENDPOINT, body);
}

export function update(id, body) {
    return api.put(`${ENDPOINT}/${id}`, body);
}

export function deleteOne(id) {
    if (id == null || id == '') {
        return Promise.reject(new Error('id is empty'));
    }
    return api.delete(`${ENDPOINT}/${id}`);
}

export function deleteMany(ids) {
    if (ids == null || ids == '' || ids.length == 0) {
        return Promise.reject(new Error('id is empty'));
    }
    const params = new URLSearchParams({
        [`${ID_ATTRIBUTE}[in]`]: ids.join()
    });
    let paramStr = params.toString();
    if (!paramStr) {
        paramStr = `${ID_ATTRIBUTE}[null]=true`;
    }
    return api.delete(`${ENDPOINT}?${paramStr}`);
}
