// Essential for all components
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import {
    Button,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell
} from "@material-ui/core";

import moment from "moment";
import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import BaseListPage from "../../components/101BaseListPage/BaseListPage";
import {connect} from "react-redux";
import * as apiIndustry from "../../Api/_ApiIndustry";
import * as apiIndustryAttribute from "../../Api/_ApiIndustryAttribute";
import * as apiIndustryEssentialAttribute from "../../Api/_ApiIndustryEssentialAttribute";
import {isAdmin} from "../../utils/Utility";
import {getValAfterSlash,handleSpecialFieldName} from "../../utils/PaginationUtils";

// Define column names
const columnDefs = [
    {id: 'name', numeric: false, disablePadding: false, label: 'Name'},
    {id: 'data_type_display', numeric: false, disablePadding: false, label: 'Data Type'},
    {id: 'required_display', numeric: false, disablePadding: false, label: 'Required'},
    {id: 'repeatable_display', numeric: false, disablePadding: false, label: 'Repeatable'},
    {id: 'sequence', numeric: true, disablePadding: false, label: 'Sequence'},
    {id: 'createddate', numeric: false, disablePadding: false, label: 'Created Date', width: 200, type: 'date'}
];

const rowsPerPageDefault = 10;
const orderByDefault = 'sequence';
const orderDefault = 'asc';

class IndustryDetails extends Component {

    state = {
        detail: null,

        // industry attribute list
        subListData: [],

        // industry essential attribute list
        essentialAttributeListData: [],

        openSnackbar: false,
        messageSnackbar: '',
        variantSnackbar: '',
        //pagination
        amountOfData: '',
        currentPage: 0,
        rowsPerPage: rowsPerPageDefault,
        orderBy: orderByDefault,
        order: orderDefault
    };

    componentDidMount() {
        this.getDetail();
    }

    getDetail = () => {
        const { i18n, t } = this.props;
        const { currentPage, rowsPerPage, orderBy, order } = this.state;
        let params = {};
        apiIndustry.getDetail(this.props.id, params).then(obj => {
            if(obj && obj.status === 200) {
                if(obj.data) {
                    const detail = obj.data;
                    const itemBreadcrumb = [
                        {
                            title: t('IndustryManagement:breadcrumb'),
                            link: '/' + i18n.language + '/industry-management'
                        },
                        {
                            title: detail.name,
                            link: null
                        }
                    ];
                    this.props.setBreadcrumbP({breadcrumbData: itemBreadcrumb});
                    this.setState({
                        detail: this.prepareData(detail)
                    });
                    this._getDataDynamic(currentPage * rowsPerPage, rowsPerPage, orderBy, order, true)
                }
            } else {
                this.props.history.push('/' + i18n.language + '/login');
            }
        });
        apiIndustryEssentialAttribute.getList({'$orderby': orderBy}).then(rs => {
            if (rs && rs.status === 200) {
                this.setState({
                    essentialAttributeListData: rs.data
                });
            } else {
                console.log(rs.error)
            }
        });
    }

    _getDataDynamic = (skipAmount, rowsPerPage, orderBy, order, isResetNavAndDataAmount, searchSubject) => {
        orderBy = handleSpecialFieldName(orderBy);

        let params = {
            'industry_type': this.props.id,
            '$orderby': `${orderBy} ${order}`,
            '$top': rowsPerPage,
            '$skip': skipAmount,
        }
        apiIndustryAttribute.getList(params).then(rs => {
            if (rs && rs.status === 200) {
                if (isResetNavAndDataAmount) {
                    const dataSum = getValAfterSlash(rs.headers['content-range']);
                    this.setState({amountOfData: dataSum});
                }
                this.setState({
                    subListData: rs.data
                });
            } else {
                console.log(rs.error)
            }
        })
    }

    back = () => {
        const { i18n } = this.props;
        this.props.history.push('/' + i18n.language + '/industry-management?previous=true');
    }

    goToEditPage = () => {
        const { i18n } = this.props;
        this.props.history.push('/' + i18n.language + `/industry/${this.props.id}`);
    }

    _createButtonAction = () => {
        const {i18n} = this.props;
        this.props.history.push('/' + i18n.language + `/industry-attribute/${this.props.id}`);
    }

    _updateButtonAction = (id) => {
        const {i18n} = this.props;
        this.props.history.push('/' + i18n.language + `/industry-attribute/${this.props.id}/${id}`);
    }

    _deleteButtonAction = (ids, rowsPerPage) => {
        apiIndustryAttribute.deleteMany(ids).then(rs => {
            if (rs.status === 204) {
                this.setState({
                    openSnackbar: true,
                    messageSnackbar: 'Delete industry attribute(s) successfully',
                    variantSnackbar: 'success'
                })
                this._getDataDynamic(0, rowsPerPage, orderByDefault, orderDefault, true);
            } else {
                console.log("eCb : ", rs);
            }
        });
    }

    prepareData = data => {
        const item = {...data};
        item.update_date = item.lastmoddate ? moment(item.lastmoddate).format('YYYY-MM-DD') : '';
        return item;
    };

    prepareEssentialAttributeListData = data => {
        return (data && data.length > 0) ? (
            data.map(item => {
                return {
                    ...this.prepareSubListDatum(item),
                    id: item.industry_type_essential_attribute_id
                };
            })
        ) : [];
    }

    prepareSubListData = data => {
        return (data && data.length > 0) ? (
            data.map(item => {
                return {
                    ...this.prepareSubListDatum(item),
                    id: item.industry_type_attribute_id
                };
            })
        ) : [];
    };

    prepareSubListDatum = (item) => {
        let data_type_display;
        switch (item.data_type) {
            case 'text':
                data_type_display = 'Text';
                break;
            case 'number':
                data_type_display = 'Number';
                break;
            case 'image':
                data_type_display = 'Image';
                break;
            default:
                data_type_display = item.data_type;
                break;
        }
        return {
            ...item,
            data_type_display: data_type_display,
            required_display: item.required ? 'Yes' : 'No',
            repeatable_display: item.repeatable ? 'Yes' : 'No',
            update_date: moment(item.lastmoddate).format('YYYY-MM-DD'),
        };
    }

    render() {

        const { auth, t } = this.props;
        const { detail, currentPage, subListData, essentialAttributeListData, order, orderBy, rowsPerPage, amountOfData } = this.state;

        return (
            detail && (
                <div>
                    <div className="main__container flex-center-item">
                        <div className="full-width">
                            <Grid container xm={12} alignItems="center">
                                {isAdmin(auth.userInfo ? auth.userInfo.role : null) ?
                                    <Grid item xs={12} className="button-wrapper form-item pt20">
                                        <Button type="button" className="primary-button" onClick={() => { this.goToEditPage() }}>{t("IndustryManagement:button.edit")}</Button>
                                    </Grid>
                                : null}
                                <Grid item xs={3} className="form-item">
                                    {t("IndustryManagement:name")}
                                </Grid>
                                <Grid item xs={9} className="form-item">
                                    {detail.name}
                                </Grid>
                                <Grid item xs={3} className="form-item">
                                    {t("IndustryManagement:sequence")}
                                </Grid>
                                <Grid item xs={9} className="form-item">
                                    {detail.sequence}
                                </Grid>

                                <Grid item xs={3} className="form-item">
                                    {t("IndustryManagement:updatedDate")}
                                </Grid>
                                <Grid item xs={9} className="form-item">
                                    {moment(detail.lastmoddate).format('YYYY-MM-DD')}
                                </Grid>
                                <Grid item xs={3} className="form-item">
                                </Grid>

                                <Grid item xs={12} className="pt30">
                                    <h4>{t("IndustryManagement:industryAttributes")}</h4>
                                    <BaseListPage
                                        pageId={'industry-attribute'}
                                        mainClass={'industry-attribute'}
                                        primaryKey={'industry_type_attribute_id'}
                                        displaySearchBar={false}

                                        displayActionBar={true}

                                        createButton={true}
                                        createButtonText={'Add'}
                                        createButtonAction={this._createButtonAction}
                
                                        updateButton={true}
                                        updateButtonText="Edit"
                                        updateButtonAction={this._updateButtonAction}
                
                                        deleteButton={true}
                                        deleteButtonText="Delete"
                                        deleteButtonAction={this._deleteButtonAction}

                                        getDataDynamic={this._getDataDynamic}
                                        currentPage={currentPage}
                                        rows={columnDefs}
                                        data={this.prepareSubListData(subListData)}
                                        displaySelectBox={true}
                                        orderDefault={order}
                                        orderByDefault={orderBy}
                                        rowsPerPageDefault={rowsPerPage}
                                        rowsPerPageOptions={[5, 10, 20]}
                                        amountOfData={amountOfData}
                                    />
                                </Grid>

                                <Grid item xs={12} className="pt30">
                                    <h4>{t("IndustryManagement:industryEssentialAttributes")}</h4>
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell>Data Type</TableCell>
                                                    <TableCell>Required</TableCell>
                                                    <TableCell>Repeatable</TableCell>
                                                    <TableCell>Sequence</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {essentialAttributeListData.map(essentialAttribute => {
                                                    return <TableRow
                                                        key={essentialAttribute.industry_type_essential_attribute_id}
                                                    >
                                                        <TableCell>{essentialAttribute.name}</TableCell>
                                                        <TableCell>{essentialAttribute.data_type_display}</TableCell>
                                                        <TableCell>{essentialAttribute.required_display}</TableCell>
                                                        <TableCell>{essentialAttribute.repeatable_display}</TableCell>
                                                        <TableCell>{essentialAttribute.sequence}</TableCell>
                                                    </TableRow>
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>

                                <Grid item xs={12} className="button-wrapper form-item pt20">
                                    <Button type="button" className="primary-button" onClick={() => { this.back() }}>Back</Button>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            )
        );
    }
}
const mapStateToProps = (state) => ({
    auth: state.auth,
    breadcrumbArr: state.breadcrumb.breadcrumbArr
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data))
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(IndustryDetails)));