import { api, getBaseUrl } from "./_ApiFactoryWithHeader";

export const apiConfiguration = {
    getConfigurationList: (platform, params, cancelToken) => {
        const url = getBaseUrl(platform) + '/configurations';
        return api.get(url, params, null, cancelToken);
    },
    getConfigurationDetail: (platform, id, params, cancelToken) => {
        const url = getBaseUrl(platform)  + '/configurations/' + id;
        return api.get(url, params, null, cancelToken);
    },
    updateConfiguration: (platform, id, body) => {
        const url = getBaseUrl(platform)  + '/configurations/' + id;
        return api.put(url, body);
    },
};