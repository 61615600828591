// @material-ui
import { createMuiTheme } from '@material-ui/core/styles';

import {
    SCREEN_SIZE_SM,
    SCREEN_SIZE_MD,
    SCREEN_SIZE_LG
} from '../../../config';

export const theme = createMuiTheme({
    // typography: {
    //     useNextVariants: true,
    // },
    breakpoints: {
        values: {
            xs: 0,
            sm: SCREEN_SIZE_SM,
            md: SCREEN_SIZE_MD,
            lg: SCREEN_SIZE_LG,
            xl: 1920
        }
    },
    // ripple: {
    //     color: 'red',
    // },
    palette: {
        primary: {
            main_feature: '#1f2bae',
            main: '#444',
            light: '#67dbfc',
            dark: '#007a98',
            contrastText: '#000',
        },
        secondary: {
            main: '#02de72',
            light: '#64ffa2',
            dark: '#00ab44',
            contrastText: '#fff',
        },
    },
});