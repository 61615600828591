import qs from 'querystringify';
import { api, getBaseUrl } from "./_ApiFactoryWithHeader";

export const apiReport = {
    getReports: (platform, params) => {
        const url = `${getBaseUrl(platform)}/reports`;
        return api.get(url, params, null);
    },
    downloadReport: (platform, id, params, option) => {
        let url = `${getBaseUrl(platform)}/download_reports/${id}`;
        const query = qs.stringify(params);
        if (query.length > 0) {
          url += `?${query}`;
        }
        return api.getXlsxFile(url,{ responseType: 'blob', ...option });
    },
};
